import React, { useState, useRef } from "react";
import "./Contact.css";
import axios from "axios";
import emailjs from "@emailjs/browser";
import loc from "../../Assests/loc.png";
import email3 from "../../Assests/email3.png";
import insta from "../../Assests/insta.png";
import gif from "../../Assests/bb.gif";
import contactusCircle from "../../Assests/contactusCircle.png";
import Fade from 'react-reveal/Fade';
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Grid } from "@mui/material";
import swal from "sweetalert";
const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState();
  const form = useRef(null);

  console.log("keys", keys?.firstName);

  const handleSubmitSendEmail = (values, errors) => {
    console.log("forms value",values)

    console.log(errors);
    if (
      values?.firstName == "" ||
      values?.lastName == "" ||
      values?.email == "" ||
      Object.keys(errors).length !== 0 ||
      values?.phoneinput1 == "" ||
      values?.phoneinput2 == "" ||
      values?.phoneinput3 == "" ||
      values?.message == ""
    ) {
      swal("fill all the fileds!", "", "error");
    } else {
      var templateParams = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phoneinput1 + values?.phoneinput2 + values?.phoneinput3,
        message: values?.message,
      };
      axios
        .post("https://globx.ca/api/api/sendEmail", {
          templateParams,
        })
        .then(function (response) {
          console.log(response);
          swal("email sent ", "", "success");
        })
        .catch(function (error) {
          console.log(error);
          swal(error, "", "error");
        });
    }
  };

  return (
    <>
      <div className="contact_wrapper" id="contact">
        <div className="contact_heading">GET IN TOUCH</div>
        <div className="contact_sub_heading">CONTACT US</div>
        <div className="contact_us_form_wrap">
        <Fade left>
          <div className="contact_left">
            <div className="location_wrap">
              <div className="loc_content">
                <img src={loc} />
                TORONTO, ONTARIO
              </div>
              <div className="loc_content loc_scond_ele">
                <img src={email3} />
                globxcanada@gmail.com
              </div>
              <div className="loc_content">
                <img src={insta} />
                globxcanada
              </div>
            </div>
            <div className="get_toch_balls">
              <img src={gif} className="img-fluid" />
            </div>
          </div>
          </Fade>
          <div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phoneinput1: "",
                phoneinput2: "",
                phoneinput3: "",
                // password: "",
                message: "",
              }}
              onSubmit={async (values) => {
                setLoading(true);
                setKeys(values);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required("Required"),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form
                    ref={form}
                    onSubmit={handleSubmit}
                    className="form"
                    id="contactus"
                  >
                            <Fade right>
                    <div className="form_info">
                      <div className="con_us_text">CONTACT US</div>
                      <div>
                        <div className="enter_name">Name</div>
               
                        <div className="name_input_wrap">
                          <div className="f_name_wrap">
                            <input
                              className="f_name_ele"
                              placeholder="FIRST NAME"
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="f_name_wrap name_scond_ip">
                            <input
                              className="f_name_ele"
                              placeholder="LAST NAME"
                              type="text"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>  
                        <div className="enter_name">Email</div>
                        <div className="name_input_wrap">
                          <div className="email_wrap">
                            <input
                              className="f_name_ele"
                              placeholder="EMAIL"
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="enter_name">Phone</div>
                        <div className="phon_input_wrap">
                          <div className="phon_wrap">
                            <input className="f_name_ele2" name="phoneinput1"     onChange={handleChange}    value={values.phoneinput1} placeholder="###" />
                          </div>
                          <div className="phon_wrap phone_second_ip">
                            <input className="f_name_ele2" name="phoneinput2"    onChange={handleChange} value={values.phoneinput2} placeholder="###" />
                          </div>
                          <div className="phon_wrap">
                            <input className="f_name_ele2" name="phoneinput3"    onChange={handleChange} value={values.phoneinput3} placeholder="###" />
                          </div>
                        </div>
                        <div className="enter_name">Message </div>
                        <div className="text_area_werap">
                          <textarea
                            id="message"
                            name="message"
                            rows="9"
                            value={values.message}
                            onChange={handleChange}
                            className="text_area"
                            placeholder="ENTER MESSAGE"
                          ></textarea>
                        </div>
                        <div
                          className="submit_btn"
                          type="submit"
                          onClick={() => handleSubmitSendEmail(values, errors)}
                        >
                          Submit
                        </div>
                      </div>
                    </div></Fade>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>

      {/*
      <div className="contact">
        <div className="touch">
          <h5>Get In Touch</h5>
          <div>Email: Globxcanada@gmail.com</div>

          <h5>Toronto, Canada</h5>
        </div>

        <>
          <Grid>
            <Grid item lg={12}>
              <Box
                sx={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneinput1: "",
                    phoneinput2: "",
                    phoneinput3: "",
       
                    message: "",
                  }}
                  onSubmit={async (values) => {
                    setLoading(true);
                    setKeys(values);
                   
                  }}
                  validationSchema={Yup.object().shape({
          
                    email: Yup.string().email().required("Required"),
              
                  })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                    } = props;
                    return (
                      <form
                        ref={form}
                        onSubmit={handleSubmit}
                        className="form"
                        id="contactus"
                      >
                        <div className="div_div_label_input">
                          <label
                            className="fontSize18 gray_color "
                            htmlFor="fullName"
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Name *
                          </label>
                          <div className="div_label_input width100">
                            <div className="mb-4 div_div_label_input">
                              <input
                                id="firstName"
                                name="firstName"
                                placeholder="Enter your Name"
                                type="text"
                                value={values.firstName}
                                onChange={handleChange}
             
                                className={`input_field_login fontSize18 gray_color ${
                                  errors.firstName && touched.firstName
                                    ? "text-input error"
                                    : "text-input"
                                }`}
                              />

                              <label
                                className="fontSize18 gray_color "
                                htmlFor="firstName"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                First Name
                              </label>
              
                            </div>

                            <div className="mb-4 div_div_label_input">
                              <input
                                id="lastName"
                                placeholder="Enter your Name"
                                type="text"
                                value={values.lastName}
                                onChange={handleChange}
                      
                                className={`input_field_login fontSize18 gray_color ${
                                  errors.lastName && touched.lastName
                                    ? "text-input error"
                                    : "text-input"
                                }`}
                              />

                              <label
                                className="fontSize18 gray_color "
                                htmlFor="lastName"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                Last Name
                              </label>
                 
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 div_div_label_input ">
                          <label
                            className="fontSize18 gray_color"
                            htmlFor="email"
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Email Address *
                          </label>
                          <input
                            id="email"
                            placeholder="Enter your email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                
                            className={`input_field_login_email fontSize18 gray_color ${
                              errors.email && touched.email
                                ? "text-input error"
                                : "text-input"
                            }`}
                          />
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}
                        </div>
                        <div className="mb-4 div_div_label_input">
                          <label
                            className="fontSize18 gray_color"
                            htmlFor="password"
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            Phone *
                          </label>
                          <div className="div_label_input">
                            <div className="div_input_phone">
                              <input
                                id="phoneinput1"
                   
                                type="text"
                                maxLength={3}
                         
                                value={values.phoneinput1}
                                onChange={handleChange}
                  
                                className={`input_filed_phone fontSize18 gray_color ${
                                  errors.phoneinput1 && touched.phoneinput1
                                    ? "text-input error"
                                    : "text-input"
                                }`}
                              />
                              <label
                                className="fontSize18 gray_color"
                                htmlFor="phoneinput1"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                (###)
                              </label>
                0
                            </div>
                            <div className="div_input_phone">
                              <input
                                id="phoneinput2"
                
                                type="text"
                                maxLength={3}
                                value={values.phoneinput2}
                                onChange={handleChange}
                
                                className={`input_filed_phone fontSize18 gray_color ${
                                  errors.phoneinput2 && touched.phoneinput2
                                    ? "text-input error"
                                    : "text-input"
                                }`}
                              />
                              <label
                                className="fontSize18 gray_color"
                                htmlFor="phoneinput2"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                ###
                              </label>
              
                            </div>
                            <div className="div_input_phone">
                              <input
                                id="phoneinput3"
                     
                                type="text"
                                maxLength={4}
                       
                                value={values.phoneinput3}
                                onChange={handleChange}
             
                                className={`input_filed_phone fontSize18 gray_color ${
                                  errors.phoneinput3 && touched.phoneinput3
                                    ? "text-input error"
                                    : "text-input"
                                }`}
                              />
                              <label
                                className="fontSize18 gray_color"
                                htmlFor="phoneinput3"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                ####
                              </label>
         
                            </div>
                          </div>
                        </div>
                        <div className="div_input_phone">
                          <legend
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Message *
                          </legend>

                          <textarea
                            id="message"
                            name="message"
                            rows="4"
                            cols="50"
                            value={values.message}
                            onChange={handleChange}
                            placeholder="Write your message"
                            className={`input_field_login_email fontSize18 gray_color ${
                              errors.message && touched.message
                                ? "text-input error"
                                : "text-input"
                            }`}
                          ></textarea>
        
                        </div>

                        <div className="div_btn_submit dflex ">
                          <button
                            className="btn_submit fontSize24 white_color"
                            type="submit"
              
                            onClick={() =>
                              handleSubmitSendEmail(values, errors)
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </>
      </div>*/}
    </>
  );
};

export default Contact;
