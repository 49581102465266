import React from "react";
import banner from "../../Assests/banner.mp4";
import smallcircle from "../../Assests/smallcircle.png";
import largecaricle from "../../Assests/largecaricle.png";
import ff from "../../Assests/ff.gif";
import gg from "../../Assests/gg.gif";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import FadeIn from "react-fade-in";

import "./Banner.css";

const Banner = () => {
  return (
    <>
      <div className="banner_wrapper">
        <Fade left>
          <div className="small_cir">
            <img src={ff} className="img-fluid" />
          </div>{" "}
        </Fade>

        <div className="large_ball">
          <Fade bottom>
            <video className="vedio_dis" height="auto" autoPlay muted loop>
              <source src={banner} type="video/mp4" />
            </video>{" "}
          </Fade>

          {/*<img src={largecaricle} className="img-fluid" />*/}
          <a href="#whatDo" className="lern_more_link">
            <div className="banner_btn_box">
              <div className="learn_more_btn">LEARN MORE</div>
            </div>
          </a>
        </div>

        <Fade right>
          {" "}
          <div className="right_balls">
            <img src={gg} className="img-fluid" />
          </div>
        </Fade>
      </div>
      <div className="banner_sep"></div>
    </>
  );
};

export default Banner;
