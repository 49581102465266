import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <>
    <div className='footer_sep'></div>
    <div className="mainFooter">
  <div className='footer_items'>GLOBX</div>
  <div className='footer_items'>Powered By GLOBX</div>
  <div className='footer_items'>CONTACT US</div>
   </div>
    </>

  )
}

export default Footer