import React from "react";
import smallcircle from "../../Assests/smallcircle.png";
import plusIcon from "../../Assests/plusIcon.png";
import second from "../../Assests/second.png";
import we1 from "../../Assests/we1.png";
import ff from "../../Assests/ff.gif";
import dd from "../../Assests/dd.gif";
import we2 from "../../Assests/we2.png";
import "./WhatIsGlobx.css";
import Fade from 'react-reveal/Fade';

const WhatIsGlobx = () => {
  return (
    <>
      <div className="whats_globex" id="what">
        <div className="whats_globex_heading" id="what">
          WHAT IS<span className="globex_span"> GLOBX </span>
        </div>
        <div className="whats_globex_sub_heading">
          GLOBX IS A SOLUTIONS AGENCY
        </div>
        <div className="whats_globex_inner">
        <Fade left>
          <div>
            <img src={ff} className="img-fluid1" />
          </div>
          </Fade >
          <Fade bottom>
          <div className="whats_right_sec">
            <div className="left_plus_icon">
              <img src={plusIcon} />
            </div>
            <div className="whats_right_text">
              GLOBX LEVERAGES THE POWER OF{" "}
              <span className="globex_span"> DIGITAL MARKETING</span> TO HELP
              YOUR BUSINESS GROW.
            </div>
            <div className="whats_right_text">
            <span className="globex_span"> WE </span>KNOW HOW TO PINPOINT, AUDIENCES,<span className="globex_span">WE </span>UNDERSTAND TECHNOLOGY,<span className="globex_span"> WE</span>
            &nbsp; HELP SCALE YOUR MARKETING BUDGET TO INCREASE SALES AND USER
              TRAFFIC.
            </div>
            <div className="right_plus_icon">
              <img src={second} />
            </div>
          </div>
          </Fade>
        </div>
      </div>
      <div className="whats_globex2" id="about">
        <div className="whats_globex_heading2">
          WHAT ARE &nbsp;<span className="globex_span">WE</span>
        </div>
        <div className="whats_globex_sub_heading2">
          GLOBX IS A SOLUTIONS AGENCY
        </div>
        <div className="whats_globex_inner">
        <Fade bottom>
          <div className="whats_right_sec2">
            <div className="left_plus_icon2">
              <img src={we2} />
            </div>

            <div className="whats_right_text2">
              CANADIAN BASED TECH CONSULTANCY THAT IS CUSTOMER CENTRIC, ALWAYS
              GIVING THE BEST SOLUTIONS WHETHER IT BE CREATING A FULL SCALE
              DIGITAL REVAMP OR A SIMPLE INSTAGRAM POST.
            </div>
            <div className="right_plus_icon2">
              <img src={we1} />
            </div>
          </div>
          </Fade>
          <Fade right>
          <div>
            <img src={dd} className="img-fluid" />
          </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default WhatIsGlobx;
