import React, { useState } from "react";
import "./WhatWeDo.css";
import marketing from "../../Assests/marketing.gif";
import social from "../../Assests/social.gif";
import emailmarketing from "../../Assests/emailmarketing.gif";
import sec1 from "../../Assests/sec1.png";
import sec3 from "../../Assests/sec3.gif";
import seo from "../../Assests/seo.gif";
import { BsPlusLg } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import Slide from 'react-reveal/Slide';

const WhatWeDo = () => {
  const [isActive, setisActive] = useState(3);
  return (
    <>
      <div className="what_we_do" id="whatDo">
        <div className="what_we_heading">WHAT WE DO</div>
        <Slide bottom><div className="what_sub_heading">SERVICES WE PROVIDE</div>          </Slide>
        <div className="scrol_wrap">
          <div className="conditionl_opt">
            <div
              className={isActive === 1 ? "con_items_active" : "con_items"}
              onClick={() => setisActive(1)}
            >
     DIGITAL MARKETING
            </div>
            <div
              className={isActive === 2 ? "con_items_active" : "con_items"}
              onClick={() => setisActive(2)}
            >
              SOCIAL MEDIA MARKETING
            </div>
            <div
              className={isActive === 3 ? "con_items_active" : "con_items"}
              onClick={() => setisActive(3)}
            >
              EMAIL MARKETING
            </div>
            <div
              className={isActive === 4 ? "con_items_active" : "con_items"}
              onClick={() => setisActive(4)}
            >
              PRODUCT DESIGN DEVELOPMENT
            </div>
            <div
              className={isActive === 5 ? "con_items_active" : "con_items"}
              onClick={() => setisActive(5)}
            >
              SEO SERVICE SOLUTIONS
            </div>
          </div>
        </div>
        <div className="con_sep"> </div>
        {/*<=======marketing=========>*/}

        {isActive === 1 ? (
    
            <div className="digital_marketing_wrap">
              <div>
              <Fade left>  <img src={marketing} className="img-fluid" />     </Fade>
              </div>
              <div>
              <Fade right> 
                <div className="opt_text">
                  We offer industry leading, enterprise digital marketing
                  systems as part of our website services. Our digital marketing
                  websites are fully bespoke, adapted to work seamlessly with
                  your business model - whether it be e-commerce, SAAS (software
                  as a service), international sales; our expert Toronto based
                  web design team have you covered .
                </div>

                <br />
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon" />
                  Industry leading, scalable digital marketing systems
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon plus_icon_loc"  />
                  Complete control over your site with easy - in house
                  management of your online store
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon1"  />
                  Integration of advanced reporting tools such as Google
                  Analytics to trash user behaviour and optimise for revenue
                  /conversations.
                </div>
                <div className="opt_last_text">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Drive leads and improve customer retention
                </div>
                </Fade> 
              </div>
            </div>
     
        ) : isActive === 2 ? (
  
            <div className="digital_marketing_wrap">
            <Fade   left>   <div>
             
                <div className="opt_text">
                  Ensuring your businesses' social media pages are up to date
                  are important for allowing potential customers to connect and
                  engage with your content. With the implementation of a strong
                  strategy and the ability to keep your audience engaged you are
                  able to create an inlet where leads and future connections can
                  be established.
                </div>

                <br />
                <div className="opt_text22">
                  <BsPlusLg className="plus_icon opt_sec2_plus"  />
                  Automated tracking services and content engagement review
                </div>
                <div className="opt_text22">
                  <BsPlusLg className="plus_icon"  />
                  Content strategy & brand creation
                </div>
                <div className="opt_text22">
                  <BsPlusLg className="plus_icon"  />
                  Drive leads and improve customer retention
                </div>
                <div className="opt_text22">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Create more of a personal connection with your marke
                </div>
     
              </div>
              </Fade>
              <div>
              <Fade   right> <img src={social} className="img-fluid" /></Fade>
              </div>
            </div>
        
        ) : isActive === 3 ? (
          <div className="digital_marketing_wrap1">
            <Fade left>
              <div className="opt_sec3">
                <div className="opt_text11">
                  Lead generation and marketing done through email is one of the
                  oldest yet most reliable sources of business when you can get
                  it done the correct way. Globx is a resource you can trust
                  when it comes to keeping your email content fresh and simple.
                </div>

                <br />
                <div className="opt_text29">
                  <BsPlusLg className="plus_icon opt_sec2_plus1"  />
                  We offer a personalised email boutique for implementing our
                  formulas within the heart of your business.
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon"  />
                  Live portal for lead analysis & stat tracking
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon"  />
                  Proven templates for higher conversion rates
                </div>
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Affiliate marketing and traffic analysis
                </div>
              </div>
            </Fade>
     
              <div>
              <Fade right>  <img src={emailmarketing} className="img-fluid email_img" />      </Fade>
              </div>
      
          </div>
        ) : isActive === 4 ? (
          <div className="product_design">
            <Fade left>
              <div className="product_sec1">
                <div className="pro_sec1_text">
                  With Globx, design implementation has never been easier with
                  our 7 stage process personalised for every business. By
                  sticking to our tailored development plan it allows for your
                  product/brand to gain its own identity.
                </div>
                <div>
                  <img src={sec1} />
                </div>
              </div>
              <div className="pro_sec_2">
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Allowing your product to individualise
                </div>
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Creating product/ brand shoots
                </div>
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Establishing campaign
                </div>
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Each step is shown as we complete it
                </div>
              </div>
            </Fade>
        
              <div>
              <Fade right>  <img src={sec3} className="img-fluid" />   </Fade>
              </div>
         
          </div>
        ) : isActive === 5 ? (
          <div className="digital_marketing_wrap1">
            <Fade left>
              <div>
                <img src={seo} className="img-fluid" />
              </div>
            </Fade>
            <Fade right>
              <div className="opt_sec5">
                <div className="opt_text">
                  GlobX is happy to provide SEO auditing services.Having your
                  organisation at the top of a search is key for your
                  advertisements reaching the right clientele. Here at Globx we
                  know how crucial it is that your customers find you first, by
                  optimising search results we aim to direct traffic to those
                  who are interested in your product, services, or industry
                </div>

                <br />
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon plus_icon_loc"  />
                  You can trust GlobX to create an autonomous system that
                  involves little to no maintenance.
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon"  />
                  Maintaining a standard of your customer reviews
                </div>
                <div className="opt_text2">
                  <BsPlusLg className="plus_icon" />
                  Competitor analysis - off/on page optimization
                </div>
                <div className="opt_text2">
                  {" "}
                  <BsPlusLg className="plus_icon"  />
                  Progressive reporting & efficiency review
                </div>
              </div>
            </Fade>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default WhatWeDo;
