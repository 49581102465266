import React from "react";
import "./Team.css";
import Saif from "../../Assests/Saif.jpg";
import Ashwin from "../../Assests/ashwin.jpg";
import Ethan from "../../Assests/Ethan.jpg";
import Nicholas from "../../Assests/Nicholas.png";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import saif from "../../Assests/saif.png";
import ashwin from "../../Assests/ashwin.png";
import ethan from "../../Assests/ethan.png";
import smallPlus from "../../Assests/smallPlus.png";
import smallPlusRight from "../../Assests/smallPlusRight.png";
import linked from "../../Assests/linked.png";
import insta from "../../Assests/insta.png";
import mail from "../../Assests/mail.png";
import nichlo from "../../Assests/nichlo.png";
import Fade from 'react-reveal/Fade';


const Team = () => {
  const teamArray = [
    {
      heading: "DIGITAL TECHNOLOGIST",
      profileImage: saif,
      name: "Saif Jaffery",
      linked:"https://www.linkedin.com/in/saif-jaffery-a69767115/",
      insta:"https://www.instagram.com/saifery/",
      email:" saif@globxcanada.ca",

      desc: "An innovative and technology driven entrepreneur, with a wide range of business experience, Saif specializes in creating innovative solutions to complex problems, through digital concepts helping businesses to meet their goals. His skills set cuts across a number of technology and business domains including data analytics, automation, web design, and Web3 development. Saif strives to provide the better curated solutions to the customers which best serves their needs.",
    },
    {
      heading: "Digital Marketing Analyst",
      profileImage: ashwin,
      linked:"https://www.linkedin.com/in/ashwin-sanmugananthan-0132ab181/",
      insta:" https://www.instagram.com/ashwinshan/?hl=en",
      email:" ashwin@globxcanada.ca",
      name: "Ashwin Sanmugananthan",
      desc: "Ashwin leverages exceptional interpersonal skills to build and maintain effective client relations, providing consistently tailored solutions to unique customer needs to drive client satisfaction. Utilises years of technical, data analysis, general accounting, and finance experience to help elevate Globx.",
    },
    {
      heading: "Business Development",
      profileImage: ethan,
      name: "Ethan Gomes",
      linked:"https://www.linkedin.com/in/ethan-gomes-0a798013a/",
      insta:"  https://www.instagram.com/ethangomesss/",
      email:" ethan@globxcanada.ca",
      desc: "Ethan is a flexible & multi passionate individual who carries knowledge within various industries. Skilled in sales, affiliate marketing, media management, copy writing and real estate tech. Able to put you first and develop a comprehensive map for the growth outlook within your organisation.",
    },
    {
      heading: "Social Media Specialist",
      profileImage: nichlo,
      name: "Nicholas Phillibert",
      linked:"https://www.linkedin.com/in/nicholas-phillibert-276253163/",
      insta:"",
      email:"NIcholas@globxcanada.ca",
      desc: "Nicholas is a creative professional with a business mindset, driven to create winning strategies for brands by use of digital marketing. With his deep understanding of marketing and his ability to work with clients to provide results, Nicholas strives to help organise your growth with GlobX.",
    },
  ];
  return (
    <>
      <div className="team_wrapper" id="team">
        <div className="team_heading">MEET THE TEAM</div>
        <div className="team_sub_heading">FOUNDERS</div>
        <div className="team_sec">
          {teamArray.map((data) => {
            return (
              <>
                  <Fade bottom>
                <div className="team_card">
                  <div className="team_card_profile">
                    <div>
                      <img src={data?.profileImage} />
                    </div>
                    <div className="team_name">{data?.name}</div>
                  </div>
                  <div className="card_content_sec">
                    <div className="team_card_heading">
                      <div className="">
                        <img src={smallPlus} />
                      </div>
                      {data?.heading}
                      <div className="small_plus_right">
                        <img src={smallPlusRight} />
                      </div>
                    </div>
                    <div className="team_card_text">{data?.desc}</div>
                    <div className="team_card_social">
                      <div className="card_social">
                        <div className="social_icons">
                       <a href={data.linked} target="_blank">   <img src={linked} /></a>
                        </div>
                        <div className="social_icons social_second_icon">
                        <a href={data.insta} target="_blank">     <img src={insta} /></a>
                        </div>
                        <div className="social_icons">
                        <a href={`mailto:${data.email}`}>     <img src={mail} /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Fade>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Team;
