import React, { useState } from "react";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import glogo from "../../Assests/glogo.png";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="nav_bar_wrapper">
        <div className="nav_bar_container">
          <div className="globex_lgo">
            <img src={glogo} />
          </div>
          <div className="nav_right_wrapper">
            <div className="nav_items"><a href="#about" className="nav_link"> About Us</a></div>
            <div className="nav_items"><a href="#what" className="nav_link"> What Is Globx</a></div>
            <div className="nav_items"><a href="#team" className="nav_link"> Team</a></div>
            <div className="nav_items"><a href="#contact" className="nav_link">Contact Us</a></div>
          </div>
        </div>
        <div className="nav_bar_mobile_container">
          <div className="globex_lgo">
            <img src={glogo} />
          </div>
          <div onClick={handleShow}>
            <GiHamburgerMenu className="mobile_icon" />
          </div>
        </div>
        <Offcanvas scroll={true} className="mobile_side_bar" show={show} onHide={handleClose}>

     <div className="ham_burger">      <img src={glogo} /> <div onClick={()=>handleClose()}><AiOutlineClose className="close_icon" /> </div></div>

        <Offcanvas.Body>
          <div className="side_bar_items" onClick={()=>handleClose()}><a href="#about" className="nav_link"> About us</a></div>
          <div className="side_bar_items" onClick={()=>handleClose()}><a href="#what" className="nav_link">What Is Globx</a></div>
          <div className="side_bar_items" onClick={()=>handleClose()}><a href="#team" className="nav_link"> Team</a></div>
          <div className="side_bar_items" onClick={()=>handleClose()}><a href="#contact" className="nav_link">Contact Us</a></div>
    
        </Offcanvas.Body>
      </Offcanvas>
      </div>
    </>
  );
};

export default Navbar;
