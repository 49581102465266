import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Team from "./components/Team/Team";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Contact from "./components/Contact/Contact";
import WhatIsGlobx from "./components/WhatIsGlobx/WhatIsGlobx";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import "./App.css";
import Banner from "./components/Banner/Banner";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <WhatIsGlobx />


      <WhatWeDo />
      <Team />
      <Contact />

      <Footer />
    </div>
  );
}

export default App;
